import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LeafMap from './components/LeafMap';
import DisplayMap from './components/DisplayMap';
import Loader from "./components/AppComponents/Loader";
import { useState } from 'react';

function App() {
  const [ShowLoader, setShowLoader] = useState(false);


  return (
    <div className="App">
                  {ShowLoader ? <Loader></Loader> : ""}

      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LeafMap ShowLoader={ShowLoader} setShowLoader={setShowLoader}></LeafMap>} />
          <Route path="/mapimg" element={<DisplayMap />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
