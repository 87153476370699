import axios from 'axios';

axios.defaults.baseURL = (function () {
  const Url = process.env.REACT_APP_URL || 'https://api.saveallnations.com/';
  // const Url = process.env.REACT_APP_URL || 'http://localhost:8002/';
  //   console.log('env', env);
  return Url;
  //   switch (env) {
  //     case 'dev':
  //       return '';
  //     case 'stage':
  //       return '';
  //     case 'production':
  //       return '';
  //     case 'local':
  //     default:
  //       return '';
  //   }
})();


export default axios;
