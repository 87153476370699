import React, { useState } from "react";
import "../styles/Header.css";
import { Link } from "react-router-dom";
import saveAll from "../assets/images/saveAll.png";
import pslams from "../assets/images/pslams.png";
import pslamsGif from "../assets/images/pslamsGif.gif";
import saveBanner from "../assets/images/Banner.JPG";
import Counter from "./Counter";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Popup from "./UserPopup";
import axios from "../utils/axiosWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mapwrapper from "../components/SearchField/MapWrapper";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  //   var myVideos = [];

  //   window.URL = window.URL || window.webkitURL;
  //   document.getElementById("fileUp").onchange = setFileInfo;

  //   function setFileInfo() {
  //     var files = this.files;
  //     myVideos.push(files[0]);
  //     var video = document.createElement("video");
  //     video.preload = "metadata";

  //     video.onloadedmetadata = function () {
  //       window.URL.revokeObjectURL(video.src);
  //       var duration = video.duration;
  //       myVideos[myVideos.length - 1].duration = duration;
  //       updateInfos();
  //     };

  //     video.src = URL.createObjectURL(files[0]);
  //   }

  //   function updateInfos() {
  //     var infos = document.getElementById("infos");
  //     infos.textContent = "";
  //     for (var i = 0; i < myVideos.length; i++) {
  //       infos.textContent +=
  //         myVideos[i].name + " duration: " + myVideos[i].duration + "\n";
  //     }
  //   }

  const userDetails = {
    churchName: "",
    leaderName: "",
    fileFormat: "",
    image: "",
    googleLocation: "",
    language: "",
    email: "",
    mobileNo: "",
    information: "",
  };

  const [data, setData] = useState(userDetails);
  const [error, setError] = useState(false);
  const [btn, setBtn] = useState(true);

  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(data);
    if (data["email"] !== "") {
      setBtn(false);
    }
    if (data["churchName"] !== "") {
      setBtn(false);
    }
  };

  const handleSubmit = (e) => {
    if (data.email && data.information === "") {
      toast.warning("Please enter the valid email id");
      setError(true);
    } else {
    }
  };

  return (
    <div>
      <div className="outbor">
        <nav nav className="navbar navbar-expand-sm">
          <div className="container-fluid navbarHeadC">
            <img className="saveAllLogo" src={saveAll} alt="saveallimg" />

            <div className="comingSoon">
              <h1 className="comesoon">COMING SOON</h1>
              {/* <img className="saveAllBanner" src={saveBanner} alt="saveallbannerimg" /> */}
            </div>

            {/* <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}

            <div id="navbarCollapse" className="collapse navbar-collapse ">
              {/* <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                                    <Link to="/" className="nav-link active">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active">
                                        Mission
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active">
                                        About Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active">
                                        Contact Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active">
                                        Login
                                    </a>
                                </li> 
                                <li className="nav-item">
                                  <a className="nav-link active">
                                      Upload
                                  </a>
                                  
                                </li>
                  </ul> */}

              {/* <div>
                <Mapwrapper />
              </div> */}

              <div>
                <Counter />
              </div>

              <div className="pslamLogo">
                <img src={pslamsGif} alt="pslam" width="54px" />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
