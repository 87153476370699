import React from 'react';
import mapImg from '../assets/images/map12.png';

const DisplayMap = () => {
    return (
        <div>
            <img src={mapImg} alt="mapImg" />
        </div>
    );
};

export default DisplayMap;
