import React from "react";

import "../styles/Counter.css";

const Counter = () => {
  // Set the date we're counting down to
  var countDownDate = new Date("june 30, 2023 15:37:25").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="countdown"
    document.getElementById("countdown").innerHTML =
      days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdown").innerHTML = "COMING SOON";
    }
  }, 1000);


function pad(n) {
	return (n < 10 ? '0' : '') + n;
}


  return (
    <div className="timer">
      <p id="countdown"></p>
      <div id='tiles'></div>

    </div>


  );
};

export default Counter;
