import React, { useEffect, useRef, useState } from "react";
import "../styles/LeafMap.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer } from "react-leaflet/MapContainer";
import { useMap } from "react-leaflet";
// import { Map as Mapview, TileLayer, Marker, Popup, GeoJSON, Position  } from "react-leaflet";
// import { TileLayer } from "react-leaflet/TileLayer";

// import playerimg from '../assets/images/chur1.jpeg';
import song1 from "../assets/music/song.mp3";
import video1 from "../assets/videos/video1.mp4";
import video2 from "../assets/videos/PD_SAN_Ver1.0.mp4";
import video3 from "../assets/videos/JM_SAN_Ver1.1.mp4";
import video4 from "../assets/videos/video_slot_4th.mp4";


// import saveAll from '../assets/images/saveAll.png';
// import saveAllWhite from '../assets/images/saveAllLogoWhite.png';
// import bookbib from '../assets/images/book.png';

import playIcon from "../assets/icons/play-circle.png";
import pauseIcon from "../assets/icons/pause.png";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import axios from "../utils/axiosWrapper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Maposition } from "../text/default";
import L from "leaflet";
import { Map, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import icon1 from "./SearchField/constants";

import UserPopup from "./UserPopup";

import HomeIcon from "@mui/icons-material/Home";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import PhoneIcon from "@mui/icons-material/Phone";
import NavigationIcon from "@mui/icons-material/Navigation";
import ChurchIcon from "@mui/icons-material/Church";


import UploadFileIcon from '@mui/icons-material/UploadFile';

// Cordinates of Marcillac
const center = [45.269169177925754, -0.5231516014256281];
const purpleOptions = { color: "white" };





function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon1,
      },
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;



// actual start of component
const LeafMap = (props) => {

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    location: "",
  };

  const [data, setData] = useState(initialValues);

  // validation for email form
  const [error, setError] = useState(false);

  // validation for upload form
  const [error2, setError2] = useState(false);

  const [activePark, setActivePark] = useState(null);

  const [song, setSong] = useState("");
  const [btn, setBtn] = useState(true);
  const [audioBtn, setAudioBtn] = useState(true);
  const player = useRef(null);
  const videoPlayer1 = useRef(null);
  const videoPlayer2 = useRef();
  const videoPlayer3 = useRef();
  const videoPlayer4 = useRef();

  // const isPlaying = videoPlayer1.current ? videoPlayer1.current.playing : false;

  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    console.log(song);
    setSong(song1);
    console.log("after", song);
    pauseAudio();
    setTimeout(() => {
      console.log(player.current.audio.current.pause());
    }, 300);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(data);
    if (data["email"] !== "") {
      setBtn(false);
    }
  };

  const handleCancel = (e) => {
    // e.preventDefault();
    // console.log(values);
    document.getElementById("userForm").style.display = "none";
    // toast.warning('User Create');
    // player.current.audio.current.play();
  };

  const handleSubmit = (e) => {
    console.log(data, "hghgghghhgh");

    console.log(emailRegEx.test(data.email));

    if (data.email === "") {
      toast.warning("Please enter the valid email id");
      setError(true);
    } else {
      if (emailRegEx.test(data.email)) {
        axios
          .post("/createUser", data)
          .then((res) => {
            console.log(res.data);
            var data = res.data;
            console.log(data.status["code"]);
            if (data.status["code"] === 200) {
              toast.success(
                `Thanks for your details \n Please check your mail`
              );
              document.getElementById("userForm").style.display = "none";
            } else {
              toast.error(data.status["message"]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.warning("Please enter the valid email id");
        setError(true);
      }
    }
  };

  const playAudio = () => {
    player.current.audio.current.play();
    document.getElementById("playIconBtn").style.display = "none";
    document.getElementById("pauseIconBtn").style.display = "block";
  };

  const pauseAudio = () => {
    player.current.audio.current.pause();
    document.getElementById("playIconBtn").style.display = "block";
    document.getElementById("pauseIconBtn").style.display = "none";
  };

  // const props = {
  //   inputProps: {
  //     method: "POST",
  //   },
  // };

  const position = [51.505, -0.09];
  const position1 = [10, -0.09];


 
  return (
    <div className="mapclass">
      <ToastContainer
        autoClose={5000}
        position="top-right"
        className="toastAlert"
        pauseOnHover={false}
      />
      <div className="mapContent">
        <MapContainer
          center={[8.964182, 77.281364]}
          zoom={2}
          scrollWheelZoom={false}
          // animate={true}
        >
          
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <LeafletgeoSearch />

        </MapContainer>

      </div>

      <div className="playerClass">
        <button
          className="btn btn-primary"
          id="playAudioBtn"
          onClick={playAudio}
        >
          Play
        </button>
        <div className="playercontainer">
          {/* <img src={playerimg} alt="artist" /> */}
          {/* <audio
                        autoPlay
                        loop
                        controls
                        src={song}
                    /> */}

          <AudioPlayer
            preload="none"
            autoPlay={false}
            src={song}
            volume={0.5}
            loop={true}
            layout="horizontal-reverse"
            footer="Playing : OH Lord my God on PAN FLUTE - Nick Tacu"
            ref={player}
          />
        </div>
      </div>
      <div className="playPauseBtn">
        <img
          id="playIconBtn"
          onClick={playAudio}
          src={playIcon}
          alt="playIcon"
        />
        <img
          id="pauseIconBtn"
          onClick={pauseAudio}
          src={pauseIcon}
          alt="pauseIcon"
        />
      </div>
      <div className="row container-fluid videoContent">
        <div className="col-3 videoCol hideVid">
          {/* <video controls ref={videoPlayer1}>
                        <source src={video1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
        </div>
        <div className="col-3 videoCol hideVidMain">
          <video controls ref={videoPlayer2}>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="col-3 videoCol hideVidMain">
          <video controls ref={videoPlayer3}>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="container col-3 videoCol hideVidMain">
        <video controls ref={videoPlayer4}>
            <source src={video4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div className="videowrapper">
            <iframe
              // width="560"
              // height="315"
              class="responsive-iframe"
              src="https://www.youtube.com/embed/krR6yLyAYog?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div> */}
        </div>
      </div>

      <div id="userForm" className="userForm">
        <div className="col-7 card formContainer">
          <div className="formContent col-8">
            <h3 className="formContent1">
              A WEBSITE TO HOST THE CLASSIC <br />- OH LORD MY GOD (THEN SINGS
              MY SOUL) <br />
              IN ALL LANGUAGES OF THE WORLD
            </h3>
            <h4 className="formContent2">
              <b>FEATURING</b> audio/video upload by user
            </h4>
            <h4 className="formContent3">
              Send us your <b>Email</b> for updates and launch details
            </h4>
          </div>

          <div className="card formUser col-4 ">
            <TextField
              type="text"
              name="firstName"
              label="First Name"
              variant="outlined"
              className="form-control"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last Name"
              variant="outlined"
              className="form-control mt-2"
              onChange={handleInputChange}
            />
            <TextField
              type="email"
              error={error}
              helperText={error && "Email required"}
              name="email"
              label="Email"
              variant="outlined"
              className="form-control mt-2"
              placeholder="Example@gmail.com"
              autoComplete="off"
              // onBlur={validate}
              onChange={handleInputChange}
              required
            />
            <TextField
              type="text"
              name="location"
              label="Location"
              variant="outlined"
              className="form-control mt-2"
              onChange={handleInputChange}
            />
            <div className="card-footer row formUserFoot">
              <div className="btnCol col-6">
                <Button
                  className="cancelBtn"
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                >
                  {" "}
                  X{" "}
                </Button>
              </div>

              <div className="btnCol col-6 submit-btn">
                <Button
                  type="submit"
                  className="btn btn-primary"
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={btn}
                >
                  {" "}
                  SUBMIT{" "}
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="fs-style">
                  <i class="bi bi-lock-fill"></i>100% Privacy Guaranteed
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeafMap;
