import L from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export const Maposition = [
  {
    markeropening: <Marker />,
    popup: <Popup />,
    popuptext: "User 1",
    position: [51.505, -0.09],
  },
  {
    markeropening: <Marker />,
    popup: <Popup />,
    popuptext: "User 2",
    position: [25.045403, 121.526088],
  },
  {
    markeropening: <Marker />,
    popup: <Popup />,
    popuptext: " User 3",
    position: [32.313268, 35.022895],
  },
  {
    markeropening: <Marker />,
    popup: <Popup />,
    popuptext: "User 4",
    position: [52.313268, 100.022895],
  },
  {
    markeropening: <Marker />,
    popup: <Popup />,
    popuptext: "User 5",
    position: [10.1632, 76.6413],
  },
];
